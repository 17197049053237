import React,{useState} from 'react'
import {graphql, Link} from 'gatsby'
import Layout from '../../containers/layout'
import GraphQLErrorList from '../../components/graphql-error-list'
import Carousel from '../../components/carousel'
import PortableText from '../../components/portableText'
import {Formik} from 'formik';
import * as Yup from 'yup';
import {fetchDatafromAPI} from '../../lib/apiHelper'
import ACTIONS from '../../services/actions'
import {setUser} from '../../services/auth'


export const query = graphql`
    query ChangePasswordPageQuery {
        pageInfo: sanityChangePasswordPage(_id: {regex: "/(drafts.|)changePasswordPage/"}) {
            _id
            title
            banner {
                asset {
                  url
                }
            }
            _rawBody(resolveReferences: {maxDepth: 5})
        }
    }
`


const ChangePasswordPage = props => {
    const {data, errors} = props

    if (errors) {
        return (
        <Layout>
            <GraphQLErrorList errors={errors} />
        </Layout>
        )
    }

    const pageInfo = (data || {}).pageInfo
    if (!pageInfo) {
        throw new Error(
        'Missing page info.'
        )
    }

    let  banner = pageInfo.banner && pageInfo.banner.asset ? pageInfo.banner.asset.url : ''

    const [completeMessage, setCompleteMessage] = useState()

    return(
        <Layout>
            {/* banner */}
            <div className='container container-adjust-p'>
            {
                banner ? 
                <div>
                    <img src={banner} alt={pageInfo.banner ? pageInfo.banner.alt : ''} className='d-none d-sm-block' style={{margin:'0 auto', width:'100%'}} />
                </div>
                : 
                <div className="bg-brown d-none d-sm-flex" style={{backgroundImage: 'url(' + banner + ')', backgroundPosition: 'center center', backgroundSize: 'cover', width: '100%', height: '180px', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                </div>
            }
            <div className="bg-brown d-flex d-sm-none py-4" style={{width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', minHeight:100 }}> 
                {/* <div className="container">
                    <div className="row">
                        <h1 className='mb-0' style={{color: 'rgb(245, 235, 220)'}}>{pageInfo.title.toUpperCase() || 'CHANGE PASSWORD'}</h1>
                    </div>
                </div> */}
            </div>
            </div>


            {/* breadcrumb */}
            <section className="p-0">
                <div className="container">
                    <div className="row">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Change Password</li>
                        </ol>
                    </nav>
                    </div>
                </div>
            </section>


            {/* content */}
            <div className="container">
                <div className="row">

                    {
                        !completeMessage &&
                        <>
                        <div className="col-12 col-md-8" style={{margin: '0 auto'}}>
                            {pageInfo._rawBody && <PortableText blocks={pageInfo._rawBody} />}
                        </div>
                        <div className="col-md-6 mb-8" style={{margin: '0 auto'}}>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={ValidateSchema}
                                onSubmit={async (values, actions) => {
                                    setCompleteMessage(null)

                                    // add credential to values
                                    const params = new URLSearchParams(window.location.search)
                                    values.email = params.has('email') ? params.get('email') : null
                                    values.token = params.has('token') ? params.get('token') : null

                                    // if pass checking call lambda api
                                    try {
                                        // let apiEndpoint = 'https://j43jfzh1qh.execute-api.us-east-1.amazonaws.com/prod/api'
                                        let apiEndpoint = process.env.NATIVE_USER_API_ENDPOINT
                                        let data = {
                                            action: ACTIONS.RESET_PASSWORD,
                                            values: values
                                        }

                                        let response = await fetchDatafromAPI(apiEndpoint, data)
                                        // console.log('---> response: ', response)

                                        if(response.body && response.body.bSuccess && response.body.bSuccess === true){
                                            // setCompleteMessage(response.body.message)
                                            setCompleteMessage('Your password has been changed successfully.')

                                            // reset form after success
                                            actions.resetForm()

                                            // reset user
                                            setUser({})
                                        }
                                        else{
                                            actions.setErrors({failMessage: response.body.message})
                                        }

                                    } catch (error) {
                                        // console.log("log: create user -> error", error)
                                        actions.setErrors({failMessage: "Form submitting fail, Please try again or contact administrator."})
                                    }

                                }}                         
                            >
                                {({ values, errors, touched, handleSubmit, handleChange, handleBlur, isSubmitting }) => {
                                    return (
                                        <form
                                            onSubmit={(e) => {
                                                e.preventDefault() // prevent refresh page
                                                handleSubmit()
                                            }}
                                        >
                                            
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="password">New Password</label>
                                                <input name="password" type="password" className="form-control" id="password" placeholder="Password" onChange={handleChange} onBlur={handleBlur} value={values.password} />
                                                {errors.password && touched.password ? <div className="alert alert-danger mt-1 pt-2 pb-2" role="alert">{errors.password}</div> : null}
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="confirmPassword">Confirm New Password</label>
                                                <input name="confirmPassword" type="password" className="form-control" id="confirmPassword" placeholder="Confirm Password" onChange={handleChange} onBlur={handleBlur} value={values.confirmPassword} />
                                                {errors.confirmPassword && touched.confirmPassword ? <div className="alert alert-danger mt-1 pt-2 pb-2" role="alert">{errors.confirmPassword}</div> : null}
                                            </div>

                                            {
                                                isSubmitting ? 
                                                <button type="submit" className="btn btn-secondary col-12" disabled>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Submitting...
                                                </button>
                                                : <button type="submit" className="btn btn-primary col-12">Change Password</button>
                                            }

                                            {errors.failMessage && 
                                                <div className="alert alert-danger" role="alert">
                                                    <h4 className="alert-heading mb-1">Fail!</h4>
                                                    <hr className="mt-1" />
                                                    <p>{errors.failMessage}</p>
                                                </div>
                                            }
                                        </form>
                                    )
                                }}
                            </Formik>
                        </div>
                        </>
                    }

                    {completeMessage && 
                        <div className="col-12 col-md-8 mb-6" style={{margin: '0 auto'}}>
                            <div className="alert alert-success" role="alert">
                                <h4 className="alert-heading mb-1">Successfully!</h4>
                                <hr className="mt-1" />
                                <p>{completeMessage}</p>
                                <p>You can login <Link to="/auth/login">HERE</Link>.</p>
                            </div>
                        </div>
                    }
                </div>                
            </div>

        </Layout>
    )
}

const initialValues = {
    password: '',
    confirmPassword: ''
}

const ValidateSchema = Yup.object().shape({
    password: Yup.string()
        .required('New password is required.')
        .min(7, "The password must be 7 or more characters and have one each of: upper-case letter, lower-case letter, number and symbol.")
        .matches(
            new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{7,})"),
            "The password must be 7 or more characters and have one each of: upper-case letter, lower-case letter, number and symbol."
        ),
    confirmPassword: Yup.string()
        .required('Confirm new password is required.')
        .oneOf([Yup.ref("password"), null], "The new password and confirmation password do not match."),
});

export default ChangePasswordPage